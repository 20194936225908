/* eslint-disable no-console */
import i18n from "i18next"
import urlRegex from "url-regex"
import Validator from "validatorjs"

import { parse } from "../../components/pages/CreateCampaignInfoPage/linkedin-url-parser"
import {
  hasFirstConnections,
  hasGroupConnections,
  hasSecondConnections,
  hasThirdConnections,
} from "../../components/pages/CreateCampaignInfoPage/query-manipulator"
import { maxFileSize } from "../../constants/campaign-constants"
import { SIGNATURE_EDITOR } from "../../constants/email-constants"
import statisticsCurveConstants from "../../constants/statistics-curves"
import {
  basicSearchFilters,
  forbiddenNavigatorParams,
  relationshipsBasic,
  smartSequencesConditions,
} from "../../constants/validation-constants"
import { store } from "../../redux/store"
import dataUtils from "../data/data-utils"
// eslint-disable-next-line import/no-cycle
import {
  actionsHasCondition,
  getAllStepIndexes,
  getMinIndexFromAllArrays,
} from "../step/step-utils"

let allParams = {}

const validateInput = (data, rules, customMessages, attributeNames) => {
  const validator = new Validator(data, rules, customMessages)
  if (attributeNames) {
    validator.setAttributeNames(attributeNames)
  }

  if (validator.fails()) {
    const { errors } = validator.errors
    const errs = Object.keys(errors).reduce((pr, cv) => {
      const [err] = errors[cv]
      pr[cv] = err
      return pr
    }, {})
    return errs
  }
  return undefined
}

export const validateChangePassword = params => {
  const rules = {
    newPassword: "required",
    oldPassword: "required",
  }
  const customMessages = {
    "required.newPassword": i18n.t("validation.is-required"),
    "required.oldPassword": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    newPassword: i18n.t("new-password"),
    oldPassword: i18n.t("old-password"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const cleanMessage = message => {
  const cleanString = message.replace(/((){{[^{}]+}})(?!})|{+[^{}]*}+\s*/g, "$1")
  const firstMissingTags = message.match(/[^{]{([^{}]+)\}\}/g)

  const stripCorrectTags = message.replace(/({{[^{}]+}})/g, "")
  const incorrectTags = stripCorrectTags.match(/((?:[^{]|^){{[^{}]+}})(?!})|{+[^{}]*}+/g)

  const totalDigits = []

  if (incorrectTags) {
    for (let i = 0; i < incorrectTags.length; i++) {
      totalDigits.push(incorrectTags[i].length)
    }
  }
  let tagsLength
  if (firstMissingTags) {
    tagsLength = true
  } else {
    tagsLength = totalDigits.reduce((a, b) => a + b, 0)
  }

  return { lengthOfRemoveTags: tagsLength, string: cleanString }
}

export const validateChangeInvoiceInfo = params => {
  const rules = {
    companyAddress: "required|max:70",
    companyState: "required|max:30",
    companyPostalCode: "required|max:30",
    countryOfIncorporation: "required|max:3",
    companyName: "required|max:70",
    taxId: "max:70",
    companyCity: "required|max:30",
  }
  const customMessages = {
    "required.companyAddress": i18n.t("validation.is-required"),
    "max.companyAddress": i18n.t("validation.must-seventy-characters-long"),
    "required.companyCity": i18n.t("validation.is-required"),
    "max.companyCity": i18n.t("validation.must-thirty-characters-long"),
    "required.companyState": i18n.t("validation.is-required"),
    "max.companyState": i18n.t("validation.must-thirty-characters-long"),
    "required.companyPostalCode": i18n.t("validation.is-required"),
    "max.companyPostalCode": i18n.t("validation.must-thirty-characters-long"),
    "required.countryOfIncorporation": i18n.t("validation.is-required"),
    "max.countryOfIncorporation": i18n.t("validation.must-valid-iso"),
    "required.companyName": i18n.t("validation.is-required"),
    "max.companyName": i18n.t("validation.must-seventy-characters-long"),
    "max.taxId": i18n.t("validation.must-seventy-characters-long"),
  }
  // maybe we can
  const attributeNames = {
    companyAddress: i18n.t("street-address"),
    companyState: i18n.t("state"),
    companyPostalCode: i18n.t("label.postal-code"),
    countryOfIncorporation: i18n.t("country-of-incorporation"),
    companyName: i18n.t("company-name"),
    taxId: i18n.t("tax-id"),
    companyCity: i18n.t("city"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateChangeAccountName = params => {
  const rules = {
    newAccountName: "required",
  }
  const customMessages = {
    "required.newAccountName": i18n.t("validation.is-required"),
  }
  //
  const attributeNames = {
    newAccountName: i18n.t("label.account-name"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateEditSignature = (params, signatureType) => {
  /** This means that rich text signature is empty */
  params.signature = params.signature === "<div><br></div>" ? "" : params.signature

  const rules = {
    signature: [
      { required_if: ["useSignature", "true"] },
      params.useSignature && signatureType === SIGNATURE_EDITOR.RICH_TEXT
        ? { required: "signature" }
        : {},
    ],
    HTMLSignature: [
      { required_if: ["useSignature", "true"] },
      params.useSignature && signatureType === SIGNATURE_EDITOR.HTML
        ? { required: "HTMLSignature" }
        : {},
    ],
  }

  const customMessages = {
    "required_if.signature": i18n.t("enter-at-least-one-character"),
    "required_if.HTMLSignature": i18n.t("enter-at-least-one-line-of-code"),
  }

  const attributeNames = {
    signature: i18n.t("label.signature"),
    HTMLSignature: i18n.t("html-signature"),
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateNewTeamName = params => {
  const rules = {
    teamName: "required|max:512",
  }
  const customMessages = {
    "required.teamName": i18n.t("validation.is-required"),
    "max.teamName": i18n.t("validation.must-be-512-characters-long"),
  }
  const attributeNames = {
    teamName: i18n.t("team-name"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateSenderName = params => {
  const rules = {
    name: "required|max:250",
  }
  const customMessages = {
    "required.name": i18n.t("validate.sender.name.msg"),
    "max.name": i18n.t("validate.must-be-250-characters-long"),
  }
  const attributeNames = {
    name: i18n.t("label.sender-name"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

const checkForSpecialCharacters = value => {
  const isSpecialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~\p{So}]/u
  return isSpecialCharacter.test(value)
}

export const hasSpecialCharacters = param => {
  const errors = {}
  let isValid = true
  if (checkForSpecialCharacters(param.name)) {
    errors[Object.keys(param)] = i18n.t("has-special-characters.name-format.msg")
    isValid = false
  }

  if (checkForSpecialCharacters(param.smtpSenderName)) {
    errors["smtp-sender-name"] = i18n.t("has-special-characters.name-format.msg")
    isValid = false
  }
  return isValid ? false : errors
}

export const validateWrongAuth = params => {
  const rules = {
    emailAddressUpdate: "required|email",
    passwordUpdate: "required",
  }
  const customMessages = {}
  const attributeNames = {
    emailAddressUpdate: i18n.t("linkedin-email-address"),
    passwordUpdate: i18n.t("linkedin-password"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateVerifyPin = params => {
  const rules = {
    totpToken: "required",
  }
  const customMessages = {
    "required.totpToken": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    totpToken: i18n.t("label.pin"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateVerifyTwoFactorAuth = params => {
  const rules = {
    totpToken: "required",
  }
  const customMessages = {
    "required.totpToken": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    totpToken: i18n.t("two-factor-auth-pin"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateNewLeads = params => {
  const rules = {
    file: "required",
  }
  const customMessages = {
    "required.file": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    file: i18n.t("file"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

Validator.register(
  "campaign_url_basic",
  value => {
    const { dashboard } = allParams
    if (dashboard === "1") {
      const people = "people"
      const services = "services"
      const campaignSalesUrl = "linkedin.com/search/results/"
      const includes =
        value.toLowerCase().includes(`${campaignSalesUrl}${people}`) ||
        value.toLowerCase().includes(`${campaignSalesUrl}${services}`)
      return includes
    }
    return true
  },
  i18n.t("campaign.url.basic.msg"),
)

Validator.register(
  "campaign_url_basic_search_filter",
  value => {
    const { dashboard } = allParams
    if (dashboard === "1") {
      let hasBasicSearchFilters = false
      basicSearchFilters.forEach(filter => {
        if (value.includes(filter)) {
          hasBasicSearchFilters = true
        }
      })
      if (!hasBasicSearchFilters) {
        return false
      }
    }

    return true
  },
  i18n.t("campaign.url.basic.search.filter.msg"),
)

Validator.register(
  "campaign_url_basic_relationship",
  value => {
    const { dashboard } = allParams
    if (dashboard === "1") {
      let hasRelationshipsBasic = false
      relationshipsBasic.forEach(filter => {
        if (value.includes(filter)) {
          hasRelationshipsBasic = true
        }
      })
      if (!hasRelationshipsBasic) {
        return false
      }
    }

    return true
  },
  i18n.t("campaign.url.basic.relationship.msg"),
)

Validator.register(
  "campaign_url_sales",
  value => {
    const { dashboard } = allParams
    if (dashboard === "2") {
      return value.includes("linkedin.com/sales/search/people")
    }
    return true
  },
  i18n.t("campaign.url.sales.msg"),
)

Validator.register(
  "campaign_url_sales_relationship",
  value => {
    const { dashboard, newSaleNavUrl, isAnyOfConnectionDegreeChecked } = allParams
    if (dashboard === "2") {
      if (newSaleNavUrl) {
        const obj = parse(value)
        return (
          hasFirstConnections(obj.queryObject) ||
          hasSecondConnections(obj.queryObject) ||
          hasThirdConnections(obj.queryObject) ||
          hasGroupConnections(obj.queryObject)
        )
      }
      return isAnyOfConnectionDegreeChecked
    }
    return true
  },
  i18n.t("campaign.url.sales.relationship.msg"),
)

Validator.register(
  "campaign_url_sales_forbidden",
  value => {
    const { dashboard } = allParams
    if (dashboard === "2") {
      let hasForbiddenNavigatorParams = false
      forbiddenNavigatorParams.forEach(filter => {
        if (value.includes(filter)) {
          hasForbiddenNavigatorParams = filter.slice(0, -1)
        }
      })
      if (hasForbiddenNavigatorParams) {
        return false
      }
    }
    return true
  },
  i18n.t("campaign.url.sales.forbidden.msg"),
)

Validator.register(
  "campaign_url_post_engagement",
  value => {
    const { dashboard } = allParams
    if (dashboard === "post-engagement") {
      return value.includes("linkedin.com/posts")
    }
    return true
  },
  i18n.t("campaign.url.post.engagement.msg"),
)

Validator.register(
  "campaign_url_recruiter",
  value => {
    const { dashboard } = allParams
    if (dashboard === "recruiter" || dashboard === "recruiter-pipeline") {
      const isTalentSearch = value.includes("linkedin.com/talent/search")
      if (isTalentSearch) {
        return !value.includes("linkedin.com/talent/search/advanced")
      }
      const isTalentList = value.includes("linkedin.com/talent/hire")
      if (isTalentList) {
        return isTalentList
      }
      return false
    }
    return true
  },
  i18n.t("campaign.url.recruiter.msg"),
)

Validator.register(
  "campaign_url_leads_list",
  value => {
    const { dashboard } = allParams
    if (dashboard === "leads-list") {
      return value.includes("linkedin.com/sales/lists/people")
    }
    return true
  },
  i18n.t("campaign.url.leads.list.msg"),
)

export const validateManageCampaign = params => {
  allParams = params
  const rules = {
    name: "required",
    campaignUrl:
      "max:65000|required_unless:dashboard,import|campaign_url_basic|campaign_url_basic_relationship|campaign_url_sales|campaign_url_sales_relationship|campaign_url_sales_forbidden|campaign_url_post_engagement|campaign_url_leads_list|campaign_url_recruiter",
  }
  const customMessages = {
    "required.name": i18n.t("validation.is-required"),
    "required_unless.campaignUrl": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    name: i18n.t("campaign-name"),
    campaignUrl: i18n.t("campaign-url"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateFileSize = params => {
  const errors = {}
  if (params && params.size > maxFileSize) {
    errors.file = i18n.t("validate.file.size.msg")
  }
  return errors
}

export const validateCreateWebhook = (urlParams, otherParams) => {
  const rules = {
    callbackURL: "required",
  }
  const customMessages = {
    "required.callbackURL": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    callbackURL: i18n.t("callback-url"),
  }

  const errors = validateInput(urlParams, rules, customMessages, attributeNames) || {}

  if (otherParams) {
    if (otherParams.updateTypes.length === 0) {
      errors.updateTypesError = i18n.t("validate.create.webhook.type.error")
    }
    if (!otherParams.isGlobalWebhook && otherParams.selectedCampaigns.length === 0) {
      errors.selectedCampaignsError = i18n.t("validate.create.webhook.campaign.error")
    }
  }

  return errors
}

export const validateCardName = params => {
  const rules = {
    cardName: "required",
  }
  const customMessages = {
    "required.cardName": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    cardName: i18n.t("name-on-card"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateAddAccountStep2 = params => {
  const rules = {
    selectedProxy: "required",
    proxyHostWithPort: [{ required_if: ["showDefaultProxies", false] }],
    proxyUsername: [{ required_if: ["showDefaultProxies", false] }],
    proxyPassword: [{ required_if: ["showDefaultProxies", false] }],
  }
  const customMessages = {
    "required.selectedProxy": i18n.t("validation.is-required"),
    "required_if.proxyHostWithPort": i18n.t("proxy-host-with-port-required"),
    "required_if.proxyUsername": i18n.t("proxy-username-required"),
    "required_if.proxyPassword": i18n.t("proxy-password-required"),
  }
  const attributeNames = {
    selectedProxy: i18n.t("access-country"),
    proxyHostWithPort: i18n.t("proxy-host-with-port"),
    proxyUsername: i18n.t("proxy-username"),
    proxyPassword: i18n.t("proxy-password"),
    showDefaultProxies: i18n.t("show-default-proxies"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateAddAccountStep3 = params => {
  const rules = {
    emailAddress: "required|email",
    password: "required|min:6",
  }
  const customMessages = {
    "required.password": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    emailAddress: i18n.t("email-address"),
    password: i18n.t("password"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateAddLinkedinAccount = params => {
  const rules = {
    emailAddress: "required|email",
    password: "required|min:6",
    selectedProxy: "required",
    proxyHostWithPort: [{ required_if: ["showDefaultProxies", false] }],
    proxyUsername: [{ required_if: ["showDefaultProxies", false] }],
    proxyPassword: [{ required_if: ["showDefaultProxies", false] }],
  }
  const customMessages = {
    "required.password": i18n.t("validation.is-required"),
    "required.selectedProxy": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    emailAddress: i18n.t("email-address"),
    password: i18n.t("password"),
    selectedProxy: i18n.t("access-country"),
    proxyHostWithPort: i18n.t("proxy-host-with-port"),
    proxyUsername: i18n.t("proxy-username"),
    proxyPassword: i18n.t("proxy-password"),
    showDefaultProxies: i18n.t("show-default-proxies"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

Validator.register(
  "message_tags_valid",

  (value, params) => {
    if (params) {
      const { allSupportedTags } = allParams

      const tagsInMessage = value.match(new RegExp("(?:{{)(.*?)(?:}})", "ig"))
      for (const index in tagsInMessage) {
        if (tagsInMessage[index]) {
          tagsInMessage[index] = tagsInMessage[index]
            .slice(2, -2)
            .replace("&lt;", "<")
            .replace("&gt;", ">")
            .replace("&amp;", "&")

          if (
            !allSupportedTags.find(suppTag => suppTag.tag === tagsInMessage[index].toString()) &&
            !["unsubscribe", "personalizedImage"].includes(tagsInMessage[index].toString())
          ) {
            return false
          }
        }
      }
    }

    return true
  },
  i18n.t("message.tags.valid.msg"),
)

Validator.register(
  "message_tags_replacement",
  (value, params) => {
    let hasError = false
    if (Object.prototype.hasOwnProperty.call(params, "index")) {
      const { tagInfos, allSupportedTags } = allParams
      const { index: validationIndex } = params
      const allSupportedTagsWithAdditional = [...allSupportedTags, { tag: "unsubscribe" }]
      allSupportedTagsWithAdditional.forEach(({ tag }) => {
        if (value.includes(`{{${tag}}}`)) {
          if (dataUtils.showTagReplacement(tag)) {
            const currentTagData = tagInfos[validationIndex]?.find(
              currentTag => currentTag.tag === tag,
            )

            if (!currentTagData || (currentTagData && currentTagData.replaceWith.length === 0)) {
              hasError = true
            }
          }
        }
      })
    }

    return !hasError
  },
  i18n.t("message.tags.replacement.msg"),
)

Validator.register(
  "tags_replacement",
  value => {
    return value.tag === "firstName" || value.tag === "lastName" || !!value.replaceWith
  },
  i18n.t("tags.replacement.msg"),
)

export const validateCardStep = params => {
  allParams = params
  let validationErrors = {}
  for (let i = 0; i < allParams.messages.length; i++) {
    const messageObjectKey = `message-${i}`
    const subjectObjectKey = `subject-${i}`
    const signatureObjectKey = `signature-${i}`
    const daysObjectKey = `days-${i}`
    const hoursObjectKey = `hours-${i}`

    let rules = {
      [messageObjectKey]: [
        { required_if: ["action", "message"] },
        { required_if: ["action", "inMail"] },
        { message_tags_valid: true },
        { message_tags_replacement: { index: i } },
      ],
      [subjectObjectKey]: [
        !params.dontValidateSubject ? { required_if: ["action", "email"] } : {},
        !params.dontValidateSubject ? { required_if: ["action", "inMail"] } : {},
        { message_tags_valid: true },
        { message_tags_replacement: { index: i } },
      ],
      [signatureObjectKey]: [{ required_if: ["action", "inMail"] }, { between: [0, 150] }],
      emailType: [{ required_if: ["action", "email"] }],
      [daysObjectKey]: "required",
      [hoursObjectKey]: "required",
    }
    const customMessages = {
      [`required_if.${messageObjectKey}`]: i18n.t("please-enter-the-message"),
      [`required_if.${subjectObjectKey}`]: i18n.t("please-enter-the-subject"),
      [`required_if.${signatureObjectKey}`]: i18n.t("please-enter-the-in-mail-signature"),
    }
    const attributeNames = {
      emailType: i18n.t("email-type"),
      [signatureObjectKey]: i18n.t("signature"),
      [daysObjectKey]: i18n.t("days-label"),
      [hoursObjectKey]: i18n.t("hours"),
    }

    let newDataForValidation = {
      ...allParams,
      [messageObjectKey]: allParams.messages[i],
      [subjectObjectKey]: allParams.subjects[i],
      [signatureObjectKey]: allParams.signatures[i],
      [daysObjectKey]: allParams.days[i],
      [hoursObjectKey]: allParams.hours[i],
    }

    if (params.tagInfos && Array.isArray(params.tagInfos[0])) {
      params.tagInfos[0].forEach(tagInfo => {
        rules = { ...rules, [`${tagInfo.tag}-${i}`]: [{ tags_replacement: { index: i } }] }
        newDataForValidation = { ...newDataForValidation, [`${tagInfo.tag}-${i}`]: tagInfo }
      })
    }

    const validation =
      validateInput(newDataForValidation, rules, customMessages, attributeNames) || {}
    validationErrors = { ...validationErrors, ...validation }

    if (allParams.action === "email") {
      if (allParams.messages[i] === "" || allParams.messages[i] === "<p><br></p>") {
        validationErrors = {
          ...(validationErrors || {}),
          [messageObjectKey]: i18n.t("please-enter-valid-email-message"),
        }
      }
    }

    const calculateNumberOfInputCharacters = message => {
      const tagReplacementLength = 10
      const tags = message.match(/{{(.*?)}}/g) || []
      const numberOfTags = tags.length
      let sumOfTagsLengths = 0
      tags.forEach(tag => {
        sumOfTagsLengths += tag.length
      })
      return message.length - sumOfTagsLengths + numberOfTags * tagReplacementLength
    }

    if (allParams.action === "connect") {
      const numberOfInputCharacters = calculateNumberOfInputCharacters(allParams.messages[i])
      if (numberOfInputCharacters > 300) {
        validationErrors = {
          ...(validationErrors || {}),
          [messageObjectKey]: i18n.t("text-cannot-be-longer-than-300-characters"),
        }
      }
    }
  }

  if (Object.keys(validationErrors).length > 0) {
    return validationErrors
  }

  return false
}

export const validateLoginForm = params => {
  const rules = {
    email: "required|email",
    password: "required",
  }
  const customMessages = {}
  const attributeNames = {}
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateChangeSkyleadPassword = params => {
  const rules = {
    newPassword: "required|min:8",
    confirmPassword: "required|min:8|same:newPassword",
  }
  const customMessages = {}
  const attributeNames = {
    newPassword: i18n.t("new-password"),
    confirmPassword: i18n.t("confirmation-password"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateResetSkyleadPassword = params => {
  const rules = {
    resetEmail: "required|email",
  }
  const customMessages = {}
  const attributeNames = {
    resetEmail: i18n.t("email-address"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateRegisterForm = params => {
  const rules = {
    name: "required",
    email: "required|email",
    password: "required|min:8",
    passwordRepeat: "required|min:8|same:password",
    companyName: "required|max:512",
  }
  const customMessages = {}
  const attributeNames = {
    name: i18n.t("full-name"),
    email: i18n.t("email-address"),
    password: i18n.t("password"),
    passwordRepeat: i18n.t("repeat-password"),
    companyName: i18n.t("company-name"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateGenerateRegisterLink = params => {
  const rules = {
    generateRegistrationLinkCount: "required|min:1",
  }
  const customMessages = {
    "required.generateRegistrationLinkCount": i18n.t("specify-amount-of-redeems"),
    "min.generateRegistrationLinkCount": i18n.t("count-must-be-valid-value"),
  }
  const attributeNames = {
    generateRegistrationLinkCount: i18n.t("redeems"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCreateSavedReply = params => {
  const rules = {
    savedReplyTitle: "required",
    savedReplyText: "required|max:4096",
  }
  const customMessages = {
    "max.savedReplyText": i18n.t("saved-reply-text.msg"),
  }
  const attributeNames = {
    savedReplyTitle: i18n.t("title.small"),
    savedReplyText: i18n.t("reply.small"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCreateTeamTemplate = params => {
  const rules = {
    templateName: "required",
  }

  const customMessages = {}
  const attributeNames = {
    templateName: i18n.t("template-name"),
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCreateFeatureRequest = params => {
  const rules = {
    feature_title: "required",
    feature_details: "required",
  }
  const customMessages = {}
  const attributeNames = {
    feature_title: i18n.t("title.small"),
    feature_details: i18n.t("details.small"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validatePromoCode = params => {
  const rules = {
    promoCode: "required",
  }
  const customMessages = {
    "required.promoCode": i18n.t("promo-code-is-empty"),
  }
  const attributeNames = {
    promoCode: i18n.t("promo.code"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateSmtp = params => {
  const rules = {
    "smtp-username": "required",
    "smtp-password": "required",
    "smtp-ip": "required",
    "smtp-port": "required",
  }

  const customMessages = {}

  const attributeNames = {
    "smtp-username": i18n.t("smtp-username"),
    "smtp-password": i18n.t("smtp-password"),
    "smtp-ip": i18n.t("smtp-ip"),
    "smtp-port": i18n.t("smtp-port"),
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateImap = params => {
  const rules = {
    "imap-username": "required",
    "imap-password": "required",
    "imap-ip": "required",
    "imap-port": "required",
  }

  const customMessages = {}

  const attributeNames = {
    "imap-username": i18n.t("imap-username"),
    "imap-password": i18n.t("imap-password"),
    "imap-ip": i18n.t("imap-ip"),
    "imap-port": i18n.t("imap-port"),
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCustomSmtpServer = params => {
  const rules = {
    "smtp-sender-email": "required",
    "smtp-sender-name": "required",
    "smtp-username": "required",
    "smtp-password": "required",
    "smtp-ip": "required",
    "smtp-port": "required|numeric",
    "imap-username": "required",
    "imap-password": "required",
    "imap-ip": "required",
    "imap-port": "required|numeric",
  }

  const customMessages = {}

  const attributeNames = {
    "smtp-sender-email": i18n.t("sender-email"),
    "smtp-sender-name": i18n.t("sender-name"),
    "smtp-username": i18n.t("smtp-username"),
    "smtp-password": i18n.t("smtp-password"),
    "smtp-ip": i18n.t("smtp-ip"),
    "smtp-port": i18n.t("smtp-port"),
    "imap-username": i18n.t("imap-username"),
    "imap-password": i18n.t("imap-password"),
    "imap-ip": i18n.t("imap-ip"),
    "imap-port": i18n.t("imap-port"),
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const stepValidation = allTrees => {
  const { isFirstConnection, dashboard } = store.getState().forms.formData

  for (let i = 0; i < allTrees.length; i++) {
    const allElements = allTrees[i]

    const {
      allViewSteps,
      allConnectSteps,
      allConnectStepsWithUltraBoost,
      allFollowSteps,
      allInMailSteps,
      allMessageSteps,
      allEmailSteps,
      allEmailVerificationSteps,
      allIfEmailOpenedSteps,
      allIfEmailClickedSteps,
      allIfOpenInMailSteps,
      allIfConnectedSteps,
      allIfCustom,
    } = getAllStepIndexes(allElements)

    let hasTwoConditionsInARow = false
    let j = 0
    while (j < allElements.length - 1 && !hasTwoConditionsInARow) {
      if (
        smartSequencesConditions.includes(allElements[j]?.type) &&
        smartSequencesConditions.includes(allElements[j + 1]?.type)
      ) {
        hasTwoConditionsInARow = true
      }
      j++
    }

    if (hasTwoConditionsInARow) {
      return i18n.t("has.two.conditions.in.row.msg")
    }

    /** Actions validation */

    /** View - always */

    /** Connect - not first connection (inMail not exists) and only connect one time */
    if (allConnectSteps.length > 1) {
      return i18n.t("all.connect.steps.more-then-one")
    }

    /** Follow - not first connection (before connect) and only follow one time */
    if (allFollowSteps.length > 1) {
      return i18n.t("all.follow.steps.more-then-one")
    }
    const followStep = allFollowSteps.length ? allFollowSteps[0] : -1

    if (followStep !== -1 && allElements[followStep].conditions?.isConnected) {
      return i18n.t("follow-cannot-be-after-connect")
    }

    /** InMail - not first connection (connect does not exist) */
    if (
      allInMailSteps.length &&
      actionsHasCondition(allElements, allInMailSteps, "isConnected").filter(cond => cond).length
    ) {
      return i18n.t("in-mail-and-connect-step-cannot-work-together")
    }

    /** Inmail - if dashboard is basic, or recruiter, or post-engagement, user cannot have 2 Inmails in sequence */
    if (
      (dashboard === "1" ||
        dashboard === "recruiter" ||
        dashboard === "recruiter-pipeline" ||
        dashboard === "post-engagement") &&
      allInMailSteps.length > 1 &&
      allInMailSteps[allInMailSteps.length - 1] - allInMailSteps[allInMailSteps.length - 2] === 1
    ) {
      return i18n.t("dashboard-recruiter-msg")
    }

    /** Message - on first connection/CSV (after connect) */
    if (
      !isFirstConnection &&
      dashboard !== "import" &&
      allMessageSteps.length &&
      actionsHasCondition(allElements, allMessageSteps, "isConnected").filter(cond => !cond).length
    ) {
      return i18n.t("you-cannot-have-message-before-connect")
    }

    /** Email - always */

    /** Business email - after email verification step */
    for (let index = 0; index < allEmailSteps.length; index++) {
      const emailStep = allEmailSteps[index]

      const isBusinessEmail = allElements[emailStep].data.emailType === "BUSINESS_EMAIL"

      if (isBusinessEmail) {
        if (allEmailVerificationSteps.length) {
          if (emailStep < allEmailVerificationSteps[0]) {
            return i18n.t("is-business-email.msg")
          }
        } else {
          return i18n.t("is-business-email-verification.msg")
        }
      }
    }

    const minIndexViewFollowInMailConnect = getMinIndexFromAllArrays([
      allViewSteps,
      allFollowSteps,
      allInMailSteps,
      allConnectSteps,
    ])

    /** Email verification -  Basic LN search, Import CSV, Post Engagement always, after view, follow, inMail or connect */
    const campaignTypesForFindAndVerifyBusinessEmailViaLinkedin = ["1", "import", "post-engagement"]

    if (
      campaignTypesForFindAndVerifyBusinessEmailViaLinkedin.includes(dashboard) &&
      allEmailVerificationSteps.length &&
      (minIndexViewFollowInMailConnect === -1 ||
        (minIndexViewFollowInMailConnect !== -1 &&
          Math.min(allEmailVerificationSteps) < minIndexViewFollowInMailConnect))
    ) {
      return i18n.t("find-and-verify-business-email-step.msg")
    }

    /** Conditions validation */
    /** First step cannot be condition only in csv campaign */
    // if (
    //   dashboard === "import" &&
    //   [
    //     allIfEmailOpenedSteps[0],
    //     allIfEmailClickedSteps[0],
    //     allIfOpenInMailSteps[0],
    //     allIfConnectedSteps[0],
    //     allIfHasVerifiedEmail[0],
    //     allIfHasEmail[0],
    //     allIfCustom[0],
    //   ].some(el => el === 0)
    // ) {
    //   return "Your first step cannot be a condition!"
    // }

    /** Custom condition validation */
    /** First step cannot be a custom condition step */
    if (allIfCustom[0] === 0) {
      return i18n.t("first-step-cannot-be-custom-condition")
    }

    /** ifConnected - after view, follow, inMail or connect */
    if (
      allIfConnectedSteps.length &&
      (minIndexViewFollowInMailConnect === -1 ||
        (minIndexViewFollowInMailConnect !== -1 &&
          Math.min(allIfConnectedSteps) < minIndexViewFollowInMailConnect))
    ) {
      return i18n.t("if-connected-step.msg")
    }

    /** ifHasEmail - always */
    /** ifHasVerifiedEmail - always */

    /** ifOpenInMail - always, if csv then after view, follow, inMail or connect */
    if (
      dashboard === "import" &&
      allIfOpenInMailSteps.length &&
      (minIndexViewFollowInMailConnect === -1 ||
        (minIndexViewFollowInMailConnect !== -1 &&
          Math.min(allIfOpenInMailSteps) < minIndexViewFollowInMailConnect))
    ) {
      return i18n.t("if-open-in-mail-always-msg")
    }

    /** ifEmailOpened - after email step */
    if (
      allIfEmailOpenedSteps.length &&
      allEmailSteps.length &&
      Math.min(allIfEmailOpenedSteps) < Math.min(allEmailSteps)
    ) {
      return i18n.t("if-email-open.msg")
    }

    /** ifEmailLinkClicked - after email step */
    if (
      allIfEmailClickedSteps.length &&
      allEmailSteps.length &&
      Math.min(allIfEmailClickedSteps) < Math.min(allEmailSteps)
    ) {
      return i18n.t("if-email-link-clicked.msg")
    }

    const minIndexViewFollowMessageInMail = getMinIndexFromAllArrays([
      allViewSteps,
      allFollowSteps,
      allMessageSteps,
      allInMailSteps,
    ])

    if (
      dashboard === "import" &&
      allConnectStepsWithUltraBoost.length &&
      (minIndexViewFollowMessageInMail === -1 ||
        (minIndexViewFollowMessageInMail !== -1 &&
          Math.min(allConnectStepsWithUltraBoost) < minIndexViewFollowMessageInMail))
    ) {
      return i18n.t("all-connect-steps-with-ultra-boost.msg")
    }
  }
}

export const checkIfEmail = string => {
  const validEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return validEmail.test(String(string).toLowerCase())
}

export const validateEmailPreview = values => {
  const errors = {}
  let isValid = true
  values.forEach(tag => {
    if (!tag.value) {
      errors[tag.id] = i18n.t("enter-the-variable-replacement")
      isValid = false
    }

    if (tag.id === "sendToEmail") {
      if (!checkIfEmail(tag.value)) {
        errors[tag.id] = i18n.t("please-enter-valid-email")
        isValid = false
      }
    }
  })
  return isValid ? false : errors
}

export const checkForEmojis = value => {
  const isEmoji =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
  return isEmoji.test(value)
}

export const validateNoEmojis = param => {
  const errors = {}
  let isValid = true
  if (checkForEmojis(param.value)) {
    errors[param.id] = i18n.t("validate-no-emojis.msg")
    isValid = false
  }
  return isValid ? false : errors
}

export const validateScheduleCampaign = params => {
  const rules = {
    timezone: "required",
    startDate: "required",
  }
  const customMessages = {
    "required.timezone": i18n.t("validation.is-required"),
    "required.startDate": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    startUTCTime: i18n.t("selected-start-time"),
    timezone: i18n.t("selected-timezone"),
    startDate: i18n.t("selected-start-date"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateSeat = params => {
  const rules = {
    seatName: "required",
  }
  const customMessages = {
    "required.seatName": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    seatName: i18n.t("seat-name"),
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateEmailBccList = raw => {
  const emails = raw.toString().split(",")

  let valid = true
  const validEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  for (let i = 0; i < emails.length; i++) {
    if (emails[i] === "" || !validEmail.test(emails[i].replace(/\s/g, ""))) {
      valid = false
    }
  }
  return valid
}

export const validateDomainName = params => {
  const rules = {
    domain: "required",
  }
  const customMessages = {
    "required.domain": i18n.t("validation.is-required"),
  }
  const attributeNames = {
    domain: i18n.t("custom-domain-name"),
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateCustomDomain = domain => {
  let error = {}
  let isValid = true

  /** The url-regex library requires the prefix "http(s)://" to be able to validate the passed domain */
  const formattedDomain = domain.includes("http") ? domain : `http://${domain}`
  const customDomain = urlRegex({ exact: true }).test(formattedDomain)
  if (!customDomain) {
    isValid = false
    error = { domain: i18n.t("custom-domain-format-is-invalid") }
  }
  return isValid ? true : error
}

export const isValidTimestamp = timestamp => {
  if (Number.isNaN(timestamp)) {
    return false
  }
  return new Date(timestamp).getTime() > 0
}

export const validateCurves = (curves, tt) => {
  const validCurvesIds = Object.values(statisticsCurveConstants(tt)?.statisticsCurves).map(
    curve => curve.ID,
  )
  return curves.filter(curve => validCurvesIds.includes(+curve))
}

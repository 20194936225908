import { STATUS_COLOR } from "@skylead/component-library"

const LEADS_FILTERS = tt => ({
  NOT_VERIFIED_EMAILS: {
    name: "NOT_VERIFIED_EMAILS",
    text: tt("unverified-emails"),
  },
  VERIFIED_EMAILS: {
    name: "VERIFIED_EMAILS",
    text: tt("verified-emails"),
  },
  DISCOVERED: {
    name: "DISCOVERED",
    id: 1,
    backgroundColor: STATUS_COLOR.GRAY,
    text: tt("discovered"),
    leadStatus: true,
    icon: "check-16",
  },
  CONNECTED_PENDING: {
    name: "CONNECTED_PENDING",
    id: 2,
    backgroundColor: STATUS_COLOR.YELLOW,
    text: tt("connection-pending"),
    leadStatus: true,
    icon: "hourglass-empty-16",
  },
  CONNECTED_NOT_REPLIED: {
    name: "CONNECTED_NOT_REPLIED",
    id: 3,
    backgroundColor: STATUS_COLOR.YELLOW,
    text: tt("connected-not-reply"),
    leadStatus: true,
    icon: "hourglass-empty-16",
  },
  CONNECTED_REPLIED: {
    name: "CONNECTED_REPLIED",
    id: 4,
    backgroundColor: STATUS_COLOR.GREEN,
    text: tt("connected-replied"),
    leadStatus: true,
    icon: "checks-16",
  },
  REPLIED_NOT_CONNECTED: {
    name: "REPLIED_NOT_CONNECTED",
    id: 5,
    backgroundColor: STATUS_COLOR.GREEN,
    text: tt("replied-not-connect"),
    leadStatus: true,
    icon: "checks-16",
  },
  OUT_OF_OFFICE: {
    name: "OUT_OF_OFFICE",
    id: 6,
    backgroundColor: STATUS_COLOR.YELLOW,

    text: tt("out-of-office"),
    leadStatus: true,
    icon: "circle-half-diagonal-16",
  },
  COMPLEX_STEPS: {
    name: "COMPLEX_STEPS",
    text: tt("selected-steps"),
  },
  NAME: {
    name: "NAME",
    text: tt("full-name-placeholder"),
  },
  COMPANY: {
    name: "COMPANY",
    text: tt("company-placeholder"),
  },
  OCCUPATION: {
    name: "OCCUPATION",
    text: tt("occupation-placeholder"),
  },
  HEADLINE: {
    name: "HEADLINE",
    text: tt("headline-placeholder"),
  },

  ADVANCED: {
    name: "ADVANCED",
    text: tt("advanced"),
  },
})
const leadFilterConstants = tt => ({
  LEADS_FILTERS: LEADS_FILTERS(tt),
})

export default leadFilterConstants

export const advancedFilters = tt => {
  const { LEADS_FILTERS: ADVANCED_FILTERS } = leadFilterConstants(tt)
  return [
    ADVANCED_FILTERS.NAME,
    ADVANCED_FILTERS.COMPANY,
    ADVANCED_FILTERS.OCCUPATION,
    ADVANCED_FILTERS.HEADLINE,
  ]
}

export const getAllLeadStatuses = tt => {
  return Object.values(LEADS_FILTERS(tt)).filter(leadFilter => leadFilter.leadStatus)
}

import apiUtils from "../utils/api-utils"
import config from "../utils/config"

const axios = apiUtils.createAxiosInstance()

function getWhitelabelPermissions(whitelabelId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/whitelabel_permission/${whitelabelId}/get_all_whitelabel_permissions`,
  )
}

export default {
  getWhitelabelPermissions,
}

import { PromptVariant, toast } from "@skylead/component-library"
import axios from "axios"

import * as actions from "../redux/actions"
import { store } from "../redux/store"

function createAxiosInstance(tt, navigate) {
  const { language } = store.getState().user.profile
  const axiosInstace = axios.create({
    headers: {
      "user-language": language || localStorage.getItem("user-language") || "en",
    },
    withCredentials: true,
    "X-Requested-With": "XMLHttpRequest",
  })

  axiosInstace.interceptors.request.use(
    async req => {
      const newRequest = { ...req }

      return newRequest
    },
    error => {
      Promise.reject(error)
    },
  )

  axiosInstace.interceptors.response.use(
    res => {
      return res
    },
    async error => {
      const defaultMsg = tt("init-interceptors.msg")
      let err = {
        type: "Unknown Server Error",
        message: defaultMsg,
        raw_error: error,
      }

      if (
        error.response &&
        error.response.data &&
        (error.response.data.error || error.response.data.result)
      ) {
        err = error.response.data.error || error.response.data.result
        if (error.response.data.error) {
          if (error.response.data.errors) {
            if (typeof error.response.data.errors === "string") {
              err.message = error.response.data.errors
            } else {
              const errorMessages = []
              Object.values(error.response.data.errors).map(error1 =>
                errorMessages.push(error1.msg),
              )
              err.message = errorMessages
            }
          }
        }
      }

      if (!err.message) err.message = defaultMsg
      const { url } = error.response.config

      if (
        err.code === 401 &&
        !url.includes("/test_smtp") &&
        !url.includes("/test_imap") &&
        !url.includes("/oauth_email")
      ) {
        if (url.includes("/logout")) {
          return Promise.reject(err)
        }
        const { profile } = store.getState().user
        if (profile && profile.id) {
          await store.dispatch(await actions.logoutUser(navigate))
        }
      }

      if (err.code === 403 && !url.includes("start_streaming")) {
        await toast.error(err.message)
      }

      if (err.code === 404 && err.message.includes(tt("customer-not-found"))) {
        return
      }

      if (
        err.code !== 401 &&
        err.code !== 403 &&
        err.code &&
        (!url.includes("/fetch_linkedin_state") || err.code !== 409)
      ) {
        store.dispatch(actions.showPrompt(PromptVariant.ERROR, tt("error"), err.message))
      }

      // TODO: return err instead of error and handle it on all actions if needed!
      return Promise.reject(error)
    },
  )

  return axiosInstace
}

export default {
  createAxiosInstance,
}

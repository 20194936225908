import apiUtils from "../utils/api-utils"
import config from "../utils/config"

const axios = apiUtils.createAxiosInstance()

function getStatistics(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/statistics${query}`,
  )
}

function getCampaignStepsStatistics(userID, accountID, campaignId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/statistics/steps?campaignId=${campaignId}`,
  )
}

export default {
  getStatistics,
  getCampaignStepsStatistics,
}

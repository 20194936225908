import "emoji-mart/css/emoji-mart.css"
// Import all the other languages here which you want to support
import "moment/locale/ar" // Arabic
import "moment/locale/cs" // Czech
import "moment/locale/da" // Danish (Denmark)
import "moment/locale/de" // German (Germany)
import "moment/locale/en-gb" // English Global
import "moment/locale/es" // Spanish (spain)
import "moment/locale/fr" // French (France)
import "moment/locale/hi" // Hindi (Indian)
import "moment/locale/id" // Indonesian
import "moment/locale/it" // Italian
import "moment/locale/nb" // Norwegian
import "moment/locale/nl" // Dutch
import "moment/locale/pt" // Portuguese (Portugal)
import "moment/locale/sv" // Swedish
import "moment/locale/zh-cn" // Chinese

import { toast } from "@skylead/component-library"
import queryString from "query-string"
import React, { lazy, useEffect } from "react"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import io from "socket.io-client"

import ACTIONS from "../constants/ACTIONS"
import * as appActions from "../redux/actions"
import { history, store } from "../redux/store"
import apiUtils from "../utils/api-utils"
import config from "../utils/config"
import { useGlobalTranslation } from "../utils/hook"
import { getMomentLocale } from "../utils/moment-locale-helper"
import { GLOBAL_PERMISSIONS, PERMISSIONS } from "../utils/team-management/team-management-utils"
import userBehaviourUtils from "../utils/userBehaviour-utils"
import { loadExternalENVUrl, WHITELABEL_PERMISSIONS } from "../utils/whitelabel-utils"
import AuthUserRoute from "./atoms/AuthUserRoute"
import GuestUserRoute from "./atoms/GuestUserRoute"
import Modal from "./atoms/Modal"
import OverlayWrapper from "./atoms/OverlayWrapper"
import Prompt from "./atoms/Prompt"
import CardWrapper from "./organisms/CardWrapper"
import WhitelabelWidget from "./organisms/WhitelabelWidget"
import AllIconsPage from "./pages/AllIconsPage"
import CiCDPage from "./pages/CiCDPage"

const TeamManagementPage = lazy(() => import("./pages/TeamManagementPage"))
const CampaignEditPage = lazy(() => import("./pages/CampaignEditPage"))
const Campaigns = lazy(() => import("./pages/Campaigns"))
const ChatPage = lazy(() => import("./pages/ChatPage"))
const FeatureRequestsPage = lazy(() => import("./pages/FeatureRequestsPage"))
const FeatureRequestPage = lazy(() => import("./pages/FeatureRequestPage"))
const HelpCustomVariables = lazy(() => import("./pages/HelpCustomVariables"))
const Login = lazy(() => import("./pages/LoginPage"))
const TestPage = lazy(() => import("./pages/TestPage"))
const CreateCampaignInfoPage = lazy(() => import("./pages/CreateCampaignInfoPage"))
const CreateCampaignSettingsPage = lazy(() => import("./pages/CreateCampaignSettingsPage"))
const CreateCampaignComplexSteps = lazy(() => import("./pages/CreateCampaignComplexSteps"))
const MasterLoginPage = lazy(() => import("./pages/MasterLoginPage"))
const Register = lazy(() => import("./pages/RegisterPage"))
const SelectedCampaigns = lazy(() => import("./pages/SelectedCampaigns"))
const SettingsPage = lazy(() => import("./pages/SettingsPage"))
const SomethingWentWrong = lazy(() => import("./pages/SomethingWentWrongPage"))
const StatisticsPage = lazy(() => import("./pages/StatisticsPage"))
const Blacklist = lazy(() => import("./atoms/Blacklist"))
const WebhooksPage = lazy(() => import("./pages/WebhooksPage"))
const UnclaimedPage = lazy(() => import("./pages/UnclaimedPage"))
const PermissionDeniedPage = lazy(() => import("./pages/PermissionDeniedPage"))
const LinkedinOpenProfilePage = lazy(() => import("./pages/LinkedinOpenProfilePage"))
const MaintenancePage = lazy(() => import("./pages/MaintenancePage"))
const GlobalBlacklistPage = lazy(() => import("./pages/GlobalBlacklistPage"))
const HorizonPage = lazy(() => import("./pages/Horizon"))

const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { tt } = useGlobalTranslation()

  const {
    linkedinUserMessages,
    user,
    userID,
    accountNotifications,
    language: userLanguage,
  } = useSelector(state => ({
    linkedinUserMessages: state.chat.linkedinUserMessages,
    user: state.user.profile,
    userID: state.user.profile.id,
    accountNotifications: state.account.accountNotifications,
    language: state.user.profile.language,
  }))

  useEffect(() => {
    if (userLanguage) {
      getMomentLocale(userLanguage)
    } else {
      const storedLanguage = localStorage.getItem("user-language") || "en-gb"
      getMomentLocale(storedLanguage)
    }
  }, [userLanguage])

  const loadCrispChat = () => {
    if (config.REACT_APP_USER_API_KEY === "undefined") {
      /** Include the Crisp code here, without the <script></script> tags */
      window.$crisp = []
      window.CRISP_WEBSITE_ID =
        config.REACT_APP_CRISP_WEBSITE_ID !== "undefined"
          ? config.REACT_APP_CRISP_WEBSITE_ID
          : undefined
      ;(() => {
        const d = document
        const s = d.createElement("script")

        s.src = "https://client.crisp.chat/l.js"
        s.async = 1
        d.getElementsByTagName("head")[0].appendChild(s)
      })()
    }

    store.dispatch(appActions.setTimeFormat())
  }

  const paginationFix = () => {
    const maxOnPage = localStorage.getItem("maxOnPage")
    if ([200, 500].includes(+maxOnPage)) {
      localStorage.removeItem("maxOnPage")
    }
  }

  const hotjarInit = () => {
    if (window.hj) {
      window.hj("identify", userID, {})
    }
  }

  const productFruitsInit = () => {
    if (window.$productFruits) {
      const { email, fullName, userTypeId, createdAt, whitelabelId, hasActiveSubscription } = user
      const userInfo = {
        username: userID,
        email,
        signUpAt: createdAt,
        props: {
          fullName,
          userTypeId,
          whitelabelId,
          hasActiveSubscription,
        },
      }
      if (config.REACT_APP_PRODUCT_FRUITS_COMPANY) {
        window.$productFruits.push([
          "init",
          config.REACT_APP_PRODUCT_FRUITS_COMPANY,
          "en",
          userInfo,
        ])
      }
    }
  }

  const accountStatusChangeSocket = socket => {
    socket.on(
      "AccountAuthChange",
      async ({
        metadata: { messageType, userPopupMessage, onClickRedirectUrl },
        data: { message, accountGlobalStatusId },
      }) => {
        if (userPopupMessage) {
          const options = {}
          if (onClickRedirectUrl)
            options.onClick = () => {
              history.push(onClickRedirectUrl)
            }
          switch (messageType) {
            case "SUCCESS":
              toast.success(message, options)
              break
            case "WARNING":
              toast.warn(message, options)
              break
            case "ERROR":
              toast.error(message, options)
              break
            default:
              toast.info(message, options)
              break
          }
        }
        await appActions.getTeamAccounts()
        if (+accountGlobalStatusId === 3) {
          history.replace(`/users/${userID}/`)
        }
      },
    )
  }

  const infoSocket = socket => {
    socket.on(
      "Info",
      ({
        metadata: { messageType, userPopupMessage, onClickRedirectUrl, duration },
        data: { message },
      }) => {
        if (userPopupMessage) {
          const options = {}
          if (onClickRedirectUrl)
            options.onClick = () => {
              history.push(onClickRedirectUrl)
            }
          if (duration) options.autoClose = duration

          switch (messageType) {
            case "SUCCESS":
              toast.success(message, options)
              break
            case "WARNING":
              toast.warn(message, options)
              break
            case "ERROR":
              toast.error(message, options)
              break
            default:
              toast.info(message, options)
              break
          }
        }
      },
    )
  }

  const chatMessageSocket = (socket, eventType) => {
    const newMessageStatus = eventType === "SendMessageSuccess" ? "SENT/RECEIVED" : "ERRORED"

    socket.on(eventType, ({ data: { messageId } }) => {
      const newLinkedinUserMessages = {
        ...linkedinUserMessages,
        channels: {
          ...linkedinUserMessages.channels,
          ...Object.entries(linkedinUserMessages.channels).reduce(
            (channelsResult, [channelId, channel]) => {
              channelsResult[channelId] = {
                ...channel,
                threads: {
                  ...channel.threads,
                  ...Object.entries(channel.threads).reduce((threadsResult, [threadId, thread]) => {
                    threadsResult[threadId] = thread.map(message => {
                      if (message.id === messageId) {
                        return {
                          ...message,
                          messageStatus: newMessageStatus,
                        }
                      }
                      return message
                    })
                    return threadsResult
                  }, {}),
                },
              }
              return channelsResult
            },
            {},
          ),
        },
      }
      store.dispatch({
        type: ACTIONS.GET_LINKEDIN_USER_MESSAGES,
        linkedinUserMessages: newLinkedinUserMessages,
      })
    })
  }

  const notificationSocket = socket => {
    socket.on("NewNotification", ({ data }) => {
      const { activeAccountID } = store.getState().account
      if (+data.linkedinAccountId === +activeAccountID) {
        const allNotifications = {
          count: accountNotifications.count + 1,
          notifications: [data].concat(accountNotifications.notifications),
          unseen: accountNotifications.unseen + 1,
        }

        store.dispatch({
          type: ACTIONS.GET_ACCOUNT_NOTIFICATIONS,
          accountNotifications: allNotifications,
        })
        toast.success(tt("app.notification-socket.toast.success"))
      }
    })
  }

  const streamError = socket => {
    socket.on("StreamError", () => {
      toast.error(tt("app.stream-error.toast.error"))
      store.dispatch({ type: ACTIONS.STREAM_ERROR })
    })
  }

  const streamSuccess = socket => {
    socket.on("StreamSuccess", () => {
      store.dispatch({ type: ACTIONS.STREAM_SUCCESS })
    })
  }

  const webSocketConfiguration = () => {
    const socket = io(config.REACT_APP_SOCKET_URL, {
      withCredentials: true,
      transports: ["websocket", "polling"],
    })

    accountStatusChangeSocket(socket)

    infoSocket(socket)

    chatMessageSocket(socket, "SendMessageSuccess")

    chatMessageSocket(socket, "SendMessageError")

    notificationSocket(socket)

    streamError(socket)

    streamSuccess(socket)
  }

  useEffect(() => {
    history.listen(currentLocation => {
      userBehaviourUtils.navigated(currentLocation.pathname)
    })

    const params = queryString.parse(location.search)
    if (params.iframe) {
      localStorage.setItem("iframe", "true")
    }
    const whitelabelENVUrl = localStorage.getItem("whitelabelUrl")
    const { fpr: referral, whitelabelUrl } = params

    /** Load external CSS used for whitelabel color preview */
    if (whitelabelUrl) {
      loadExternalENVUrl(whitelabelUrl)
    } else if (whitelabelENVUrl) {
      /** Load external CSS and ENV for whitelabel */
      loadExternalENVUrl(whitelabelENVUrl)
    }

    if (referral) localStorage.setItem("referral", referral)

    apiUtils.createAxiosInstance(tt, navigate)

    loadCrispChat()

    paginationFix()

    webSocketConfiguration()

    hotjarInit()

    if (userID !== undefined) {
      productFruitsInit()
    }
  }, [])

  useEffect(() => {
    if (userID !== undefined) {
      productFruitsInit()
    }
  }, [userID])

  if (config.REACT_APP_UNDER_MAINTENANCE) {
    return <MaintenancePage />
  }

  return (
    <CardWrapper>
      <Modal />
      <Prompt />
      <OverlayWrapper />
      <Routes>
        <Route path="/test" element={<GuestUserRoute element={<TestPage />} />} />
        <Route path="/login" element={<GuestUserRoute element={<Login />} />} />
        <Route path="/register" element={<GuestUserRoute element={<Register />} />} />
        {/* <Route path="/register" element={<GuestUserRoute />}>
            <Register />
          </Route> */}
        <Route
          path="/all-icons-for-the-best-design-team"
          element={<GuestUserRoute element={AllIconsPage} />}
        />
        <Route path="/ci-cd-countdown" element={<GuestUserRoute element={CiCDPage} />} />
        <Route path="/horizon" element={<AuthUserRoute element={HorizonPage} />} />
        <Route
          path="/helpcenter/customvariables"
          element={<AuthUserRoute element={HelpCustomVariables} />}
        />
        <Route path="/unclaimed" element={<AuthUserRoute element={UnclaimedPage} />} />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/permission-denied"
          element={<AuthUserRoute element={<PermissionDeniedPage />} />}
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/*/permission-denied"
          element={<AuthUserRoute element={<PermissionDeniedPage />} />}
        />
        <Route
          path="/users/:userID/teams/:teamID/global-blacklist/permission-denied"
          element={<AuthUserRoute element={<PermissionDeniedPage />} />}
        />
        <Route
          path="/users/:userID/team-management/permission-denied"
          element={<AuthUserRoute element={<PermissionDeniedPage />} />}
        />
        <Route
          path="/users/:userID/teams/:teamID/team-management/permission-denied"
          element={<AuthUserRoute element={<PermissionDeniedPage />} />}
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/chat/:campaignID/:linkedinUserID"
          element={
            <AuthUserRoute
              element={<ChatPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.INBOX}
              permission={PERMISSIONS.CHAT_VIEW}
            />
          }
        />

        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/chat/:campaignID"
          element={
            <AuthUserRoute
              element={<ChatPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.INBOX}
              permission={PERMISSIONS.CHAT_VIEW}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/chat"
          element={
            <AuthUserRoute
              element={<ChatPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.INBOX}
              permission={PERMISSIONS.CHAT_VIEW}
            />
          }
        />
        <Route path="/users/:userID/" element={<AuthUserRoute element={<MasterLoginPage />} />} />
        <Route path="/" element={<AuthUserRoute />} />
        <Route
          path="/users/:userID/team-management"
          element={
            <AuthUserRoute
              element={<TeamManagementPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.TEAM_MANAGEMENT}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/team-management"
          element={
            <AuthUserRoute
              element={<TeamManagementPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.TEAM_MANAGEMENT}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/team-management/roles-and-permissions"
          element={
            <AuthUserRoute
              element={<TeamManagementPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.TEAM_MANAGEMENT}
            />
          }
        />

        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID"
          element={
            <AuthUserRoute element={<Campaigns />} permission={PERMISSIONS.CAMPAIGNS_VIEW} />
          }
        />

        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/selected-campaigns/:campaignID"
          element={
            <AuthUserRoute
              element={<SelectedCampaigns />}
              permission={PERMISSIONS.CAMPAIGN_DETAIL_VIEW}
            />
          }
        />

        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/selected-campaigns"
          element={
            <AuthUserRoute
              element={<SelectedCampaigns />}
              permission={PERMISSIONS.CAMPAIGN_DETAIL_VIEW}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/campaign/:campaignID/edit"
          element={
            <AuthUserRoute
              element={<CampaignEditPage />}
              permission={PERMISSIONS.CAMPAIGN_DETAIL_VIEW}
            />
          }
        />

        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/create-campaign/info"
          element={
            <AuthUserRoute
              element={<CreateCampaignInfoPage />}
              permission={PERMISSIONS.CAMPAIGNS_EDIT}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/create-campaign/settings"
          element={
            <AuthUserRoute
              element={<CreateCampaignSettingsPage />}
              permission={PERMISSIONS.CAMPAIGNS_EDIT}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/create-campaign/smart-steps"
          element={
            <AuthUserRoute
              element={<CreateCampaignComplexSteps />}
              permission={PERMISSIONS.CAMPAIGNS_EDIT}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/create-campaign/smart-steps"
          element={
            <AuthUserRoute
              element={<CreateCampaignComplexSteps />}
              permission={PERMISSIONS.CAMPAIGNS_EDIT}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/settings"
          element={
            <AuthUserRoute
              element={<SettingsPage />}
              permission={PERMISSIONS.SETTINGS_VIEW}
              globalPermission={GLOBAL_PERMISSIONS.BLACKLIST}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/webhooks"
          element={
            <AuthUserRoute
              element={<WebhooksPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.WEBHOOKS}
              permission={PERMISSIONS.WEBHOOKS_VIEW}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/statistics"
          element={
            <AuthUserRoute
              element={<StatisticsPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.ANALYTICS}
              permission={PERMISSIONS.CAMPAIGN_REPORTS_VIEW}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/blacklist"
          element={
            <AuthUserRoute
              element={<Blacklist />}
              whitelabelPermission={PERMISSIONS.BLACKLIST}
              permission={PERMISSIONS.BLACKLIST_VIEW}
            />
          }
        />
        <Route
          path="/users/:userID/feature-requests"
          element={
            <AuthUserRoute
              element={<FeatureRequestsPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.FEATURE_SUGGESTIONS}
            />
          }
        />
        <Route
          path="/users/:userID/feature-requests/:featureID"
          element={
            <AuthUserRoute
              element={<FeatureRequestPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.FEATURE_SUGGESTIONS}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/accounts/:accountID/stream"
          element={
            <AuthUserRoute
              element={<LinkedinOpenProfilePage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.LINKEDIN_STREAM}
              permission={PERMISSIONS.OPEN_LINKEDIN_PROFILE_VIEW}
            />
          }
        />
        <Route
          path="/users/:userID/teams/:teamID/global-blacklist"
          element={
            <AuthUserRoute
              element={<GlobalBlacklistPage />}
              whitelabelPermission={WHITELABEL_PERMISSIONS.BLACKLIST}
              globalPermission={GLOBAL_PERMISSIONS.BLACKLIST}
            />
          }
        />

        <Route element={SomethingWentWrong} />
      </Routes>
      {config.REACT_APP_WHITELABEL_SWITCHER && <WhitelabelWidget />}
    </CardWrapper>
  )
}

export default App

const CHAT_FILTER_MESSAGE_TYPE_OPTIONS = tt => [
  {
    name: tt("linkedin"),
    type: "LINKEDIN_MESSAGE",
  },
  {
    name: tt("email"),
    type: "EMAIL",
  },
]

// qqq -using in redux
const CHAT_TYPE_FILTER_DEFAULT_OPTIONS = tt => ({
  ALL: { value: "all", name: tt("all") },
  UNREAD: {
    value: "unread",
    name: tt("unread"),
    tooltip: {
      tooltipText: tt("the-messages-you-not-opened-yet."),
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 40,
    },
  },
  ARCHIVED: {
    value: "archived",
    name: tt("archived"),
  },
  UNREPLIED: {
    value: "unreplied",
    name: tt("unreplied"),
    tooltip: {
      tooltipText: tt("the-messages-you-not-replied-to"),
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 40,
    },
  },
  CONNECTED_NOT_REPLIED: {
    value: "connected_not_replied",
    name: tt("waiting"),
    tooltip: {
      tooltipText: tt("accepted-but-not-replied"),
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 60,
    },
  },
  OTHER: {
    value: "other",
    name: tt("other"),
    tooltip: {
      tooltipText: tt("messages-outside-of-your-campaigns"),
      alignItemAbove: true,
      caretBottom: true,
      tooltipTop: 40,
    },
  },
})

const CHAT_TYPE_FILTER_DEFAULT_OPTIONS_VALUES_EXCEPT_ALL = tt =>
  Object.values(CHAT_TYPE_FILTER_DEFAULT_OPTIONS(tt))
    .map(({ value }) => value)
    .filter(value => value !== CHAT_TYPE_FILTER_DEFAULT_OPTIONS(tt).ALL.value)

const DEFAULT_PROFILE_IMAGE =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/600px-Circle-icons-profile.svg.png"

/** If image is like this data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7 then it represents a single pixel and should be replaced */
const LINKEDIN_DEFAULT_PROFILE_IMAGE_V1 =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

const initialAllMessages = {
  messages: [],
  channels: [],
  loadingChatMessages: false,
  isFetched: false,
}

const initialSelectedConversation = {
  linkedinUser: { fullName: "" },
  labels: [],
  lead: {},
  belongedLeads: [],
  allMessages: initialAllMessages,
}

const chatConstants = tt => ({
  CHAT_TYPE_FILTER_DEFAULT_OPTIONS_VALUES_EXCEPT_ALL:
    CHAT_TYPE_FILTER_DEFAULT_OPTIONS_VALUES_EXCEPT_ALL(tt),
  CHAT_TYPE_FILTER_DEFAULT_OPTIONS: CHAT_TYPE_FILTER_DEFAULT_OPTIONS(tt),
  CHAT_FILTER_MESSAGE_TYPE_OPTIONS: CHAT_FILTER_MESSAGE_TYPE_OPTIONS(tt),
})

export default chatConstants

export {
  DEFAULT_PROFILE_IMAGE,
  LINKEDIN_DEFAULT_PROFILE_IMAGE_V1,
  initialAllMessages,
  initialSelectedConversation,
}

import "overlayscrollbars/styles/overlayscrollbars.css"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import "react-toastify/dist/ReactToastify.css"
import "./global.scss"
import "./primitiveColours.css"
import "./sizing.scss"
import "./theme.css"
import "./whitelabeltheme.css"

import { LoadingPage, ToastContainerWrapper } from "@skylead/component-library"
import { createBrowserHistory } from "history"
import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"

import App from "./js/components/App"
import ScrollToTop from "./js/components/atoms/ScrollToTop"
import InfoModal from "./js/components/organisms/InfoModal"
import { store } from "./js/redux/store"
import { setTheme } from "./js/utils/app-utils"
import I18nInitializer from "./js/utils/i18n"
import userBehaviourUtils from "./js/utils/userBehaviour-utils"

const history = createBrowserHistory()

setTheme()

userBehaviourUtils.initializeEventListeners()

const root = createRoot(document.getElementById("root"))
const language = localStorage.getItem("user-language") || "en"

root.render(
  <Suspense fallback={<LoadingPage />}>
    <Provider store={store}>
      <HistoryRouter history={history}>
        <ScrollToTop>
          <I18nInitializer language={language} />
          <App />
          <ToastContainerWrapper />
          <InfoModal />
          <div id="portal" />
        </ScrollToTop>
      </HistoryRouter>
    </Provider>
  </Suspense>,
)

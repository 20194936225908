import moment from "moment"

export const getMomentLocale = language => {
  // You can use a switch case or any dynamic function to select the current selected language here
  switch (language) {
    case "ar":
      moment.locale("ar")
      return
    case "cs":
      moment.locale("cs")
      return
    case "da":
      moment.locale("da")
      return
    case "de":
      moment.locale("de")
      return
    case "en-gb":
      moment.locale("en-gb")
      return
    case "es":
      moment.locale("es")
      return
    case "fr":
      moment.locale("fr")
      return
    case "hi":
      moment.locale("hi")
      return
    case "id":
      moment.locale("id")
      return
    case "it":
      moment.locale("it")
      return
    case "nl":
      moment.locale("nl")
      return
    case "nb":
      moment.locale("nb")
      return
    case "pt":
      moment.locale("pt")
      return
    case "sv":
      moment.locale("sv")
      return
    case "zh-cn":
      moment.locale("zh-cn")
      return

    default:
      moment.locale("en-gb")
  }
}

import apiUtils from "../utils/api-utils"
import config from "../utils/config"

const axios = apiUtils.createAxiosInstance()

function getSupportedCampaignTags() {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/resources/supported_message_tags`)
}

function getProxyCountries() {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/resources/proxy_countries`)
}

function getAccountStatuses() {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/resources/account_statuses`)
}

function getCancellationReasons() {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/cancellation_reasons`)
}

export default {
  getSupportedCampaignTags,
  getProxyCountries,
  getAccountStatuses,
  getCancellationReasons,
}

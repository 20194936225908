import apiUtils from "../utils/api-utils"
import config from "../utils/config"

const axios = apiUtils.createAxiosInstance()

function getAccountData(userID, accountID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}`)
}

function changeLinkedinSubscription(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/linkedin_subscription`,
    data,
  )
}

function changeAccountName(userID, accountID, name) {
  return axios.patch(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/name`, {
    name,
  })
}

function updateLinkedinCredentials(userID, accountID, email, password) {
  return axios.put(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/credentials`,
    { email, password },
  )
}

function loginAccount(userID, accountID) {
  return axios.post(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/login`,
  )
}

function resendLinkedinPin(userID, accountID) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/pin/resend`,
  )
}

function verifyLinkedinPin(userID, accountID, pin) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/pin/worker_verification`,
    { pin },
  )
}

function verifyLinkedinTwoFactorAuth(userID, accountID, twoFactorAuth) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/two_factor_auth/worker_verification`,
    { twoFactorAuth },
  )
}

function deleteSeat(userID, accountID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}`, data)
}

function reactivateAccount(userID, accountID, data) {
  return axios.put(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/reactivate`,
    data,
  )
}

function getAllWebhooks(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/webhooks${query}`,
  )
}

function createWebhook(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/webhooks`,
    data,
  )
}

function createGlobalWebhook(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/global_webhook`,
    data,
  )
}

function deleteWebhook(userID, accountID, webhookID) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/webhooks/${webhookID}`,
  )
}

function deleteGlobalWebhook(userID, accountID, webhookIDS, url, arrayOfActions) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/delete_global_webhook`,
    {
      arrayOfIds: webhookIDS,
      url,
      arrayOfActions,
    },
  )
}

function testWebhook(userID, accountID, url, action) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/webhooks/zapier_test?action=${action}`,
    { url },
  )
}

function getSettingsData(userID, accountID) {
  return axios.get(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/settings`,
  )
}

function setSettingsData(userID, accountID, data) {
  return axios.put(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/settings`,
    data,
  )
}

function getRunOnWeekends(userID, accountID) {
  return axios.get(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/get_weekend_settings`,
  )
}

function setRunOnWeekends(userID, accountID, data) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/set_run_on_weekends`,
    data,
  )
}

function setWeekendDays(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/set_weekend_days`,
    data,
  )
}

function setMaxPendingConnections(userID, accountID, maxPendingAmount) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/set_max_pending_connection`,
    maxPendingAmount,
  )
}

function setTheme(userID, accountID, theme) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/theme/${theme}`,
  )
}

function getPendingConnections(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/pending_removal`,
  )
}

function removePendingConnections(userID, accountID, shouldRemove) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/pending_removal`,
    { shouldRemove },
  )
}

function fetchLinkedinState(userID, accountID) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/fetch_linkedin_state`,
  )
}

function authenticateSMTP(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/oauth_email/authenticate`,
    data,
  )
}

function testSMTP(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/test_smtp`,
    data,
  )
}

function testImap(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/test_imap`,
    data,
  )
}

function disconnectEmail(userID, accountID, emailType) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/oauth_email/${emailType}`,
  )
}

function getAccountNotifications(userID, accountID, filter) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/notifications${filter}`,
  )
}

function seenNotifications(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/notifications/mark_seen`,
    data,
  )
}

function getAccountProxy(userID, accountID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/proxies`)
}

function getAllGlobalWebhooks(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/global_webhooks${query}`,
  )
}

function sendEmail(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/conversations/send_email_manually`,
    data,
  )
}

function connectLinkedinAccount(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/connect_linkedin`,
    data,
  )
}

function disconnectLinkedinAccount(userID, accountID) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/disconnect_linkedin`,
  )
}

function getGlobalStatus(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/global_status`,
  )
}

function streamLinkedinOpenProfile(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/start_streaming`,
  )
}

function endStream(userID, accountID, saveCookiesAndLocalStorage) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/end_streaming`,
    { saveCookiesAndLocalStorage },
  )
}

function streamLinkedinLogin(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/login_with_stream`,
  )
}

function getTimezone(userID, accountID, data) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/get_timezone`,
    data,
  )
}

function changeTimezone(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/change_timezone`,
    data,
  )
}

function getHandleOutOfOfficeEmails(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/out_of_office`,
  )
}

function setHandleOutOfOfficeEmails(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/out_of_office`,
    data,
  )
}

export default {
  /** account */
  getAccountData,
  changeLinkedinSubscription,
  changeAccountName,
  updateLinkedinCredentials,
  loginAccount,
  resendLinkedinPin,
  verifyLinkedinPin,
  verifyLinkedinTwoFactorAuth,
  deleteSeat,
  reactivateAccount,
  getAccountProxy,
  authenticateSMTP,
  testSMTP,
  testImap,
  sendEmail,
  getGlobalStatus,
  streamLinkedinOpenProfile,
  endStream,
  streamLinkedinLogin,
  getTimezone,
  changeTimezone,
  getHandleOutOfOfficeEmails,
  setHandleOutOfOfficeEmails,

  /** webhooks */
  getAllWebhooks,
  getAllGlobalWebhooks,
  createWebhook,
  createGlobalWebhook,
  deleteWebhook,
  deleteGlobalWebhook,
  testWebhook,

  /** settings */
  getSettingsData,
  setSettingsData,
  setRunOnWeekends,
  setWeekendDays,
  getRunOnWeekends,

  setMaxPendingConnections,
  setTheme,
  getPendingConnections,
  removePendingConnections,
  fetchLinkedinState,
  disconnectEmail,

  /** notifications */
  getAccountNotifications,
  seenNotifications,
  connectLinkedinAccount,
  disconnectLinkedinAccount,
}

import apiUtils from "../utils/api-utils"
import config from "../utils/config"

const axios = apiUtils.createAxiosInstance()

function fetchTranslations(language) {
  return axios.get(
    `${config.REACT_APP_TRANSLATION_URL}/translations/webapp/whitelabels/1/languages/${language}`,
    {
      withCredentials: false,
    },
  )
}

export default {
  fetchTranslations,
}
